html {
  background: url(../medias/ciel_1920_30.jpg) no-repeat center 50px fixed;
}

body {
  background-color: transparent;
}

.clickable,
.ui.table thead th.clickable {
  cursor: pointer;
}

.toastmessages {
  position: fixed;
  top: 15px;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, 0);
}

.reset-some-element {
  all: initial;
  * {
    all: unset;
  }
}

.ui.form .disabled.field {
  opacity: 1;
}

.ui.form .field.disabled > label,
.ui.form .fields.disabled > label {
  opacity: 1;
}

.ui.disabled.input {
  opacity: 1;
}

.mapView {
  height: 500px;
}
